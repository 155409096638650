<template>
  <div class="about">
    <div class="cnt">
      <h1>ABOUT US</h1>
      <div class="text">
        <div><button v-on:click="email">test</button></div>
        
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse et tortor sit amet dui auctor suscipit id eget est. Aliquam a dui et risus tempor euismod id lacinia sapien. Etiam pulvinar, justo ultrices mollis viverra, libero nisi venenatis massa, vel ultrices metus augue vitae orci. Proin vel aliquet quam. Donec diam mi, commodo non interdum vulputate, dictum ut velit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus tincidunt lectus vel mi dignissim, ac placerat sapien hendrerit. Integer vitae orci augue. Phasellus quis hendrerit dolor, ac vestibulum mauris. Donec vel urna efficitur leo rutrum sollicitudin. In commodo nisi lectus, at faucibus mi commodo vitae. Morbi vel risus blandit, porttitor felis at, fringilla est.</p><br>
      <p>Suspendisse lorem lorem, consequat nec malesuada eget, cursus ut orci. Phasellus ultrices ullamcorper diam vel tempus. Mauris imperdiet elit ut ante malesuada commodo. Donec quis dapibus sapien, a mattis purus. Proin dictum a justo ac dictum. Nullam id commodo metus, porta finibus justo. Suspendisse arcu metus, fringilla vitae porttitor nec, pulvinar non nisi. Aliquam laoreet velit a ante maximus, eget pellentesque augue molestie. Curabitur vel faucibus ligula. Integer gravida tortor erat, a bibendum magna vestibulum eu. Ut consectetur diam nunc, id aliquet nulla lacinia sit amet. In hac habitasse platea dictumst. Phasellus accumsan congue massa, ut elementum eros aliquet eu. Morbi nec ultricies dolor.</p><br>
<p>Sed venenatis elit justo, at ultricies libero convallis sed. Ut ullamcorper auctor leo, nec dignissim tortor pretium at. Nam orci ante, lacinia vel placerat sed, efficitur vel orci. Proin nisl turpis, malesuada quis dui ornare, laoreet molestie sapien. Nam malesuada, nisi a tincidunt hendrerit, enim risus accumsan turpis, ut varius lacus felis at neque. Aliquam sit amet sem viverra est gravida rhoncus malesuada at erat. Maecenas ut eros sed mi interdum egestas vitae in mauris. Sed commodo nisl purus, non blandit ligula vehicula vitae. Fusce ornare a nunc sed porttitor. Morbi at arcu sapien. Sed nec vehicula tortor. Nulla malesuada porta vehicula.</p><br>
      </div>
    </div>
    
  </div>
</template>

<script>
window.onbeforeunload = null;
  import { Options, Vue } from 'vue-class-component';
  @Options({
    name:"About",
    methods:{
    }
  })
  export default class About extends Vue {}
</script>

<style>
#nav{
    display: flex;
  }
  @media screen and (max-width: 768px) {
      #nav{text-align: center;display: block;}
      #nav ul li{float: none;}
      #nav ul{padding-right: 0;}
      #logo{
        padding: 0;
        width: 100%;
      }
      #logo img, #logo span{    
        margin: auto;
      }
    }
  .about .cnt{
    padding-top: 100px;
  }
  .about h1{
    text-align: center;
  }
  .about .text{
    margin: auto;
    width: 60%;
    border: 1px solid black;
    padding: 20px;
  }
</style>
